import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '../../styles/skipSignUpModal.scss';

function SkipSignUpModal({ isOpen, toggle }) {
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <button className="btn-close" aria-label="close" onClick={toggle}></button>
                <div className="section-image">
                    <StaticImage
                        src={'../../images/logo.png'}
                        alt="Earth Hero climate change app"
                        aria-hidden="true"
                        className="LogoImage"
                    />
                </div>
                <div className="p-4 text-center mt-4">
                    <h5 className="h5 fw-bold mb-4">
                        Are you sure you would like to explore without an account?
                    </h5>
                    <p className="fw-light mb-3">
                        If you do this your data will not be personalized or saved. To have an
                        optimal Earth Hero experience we recommend creating an account.
                    </p>
                </div>
                <div className="button-container">
                    <button onClick={toggle} className="btn-account">
                        Create An Account
                    </button>
                    <Link to="/profile/myemissions" className="btn btn-explore">
                        Yes, Let's Explore
                    </Link>
                </div>
                <div className="policy">
                    by continuing, you agree to the privacy policy and terms of service
                </div>
            </div>
        </div>
    );
}
export default SkipSignUpModal;
