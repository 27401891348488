import React, { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Link, navigate } from 'gatsby';
import Layout from '../layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import {
    faEye,
    faEyeLowVision,
    faCheckCircle,
    faXmarkCircle,
    faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FirebaseContext } from '../../utils/firebase-provider.js';
import SkipSignUpModal from '../Modals/SkipSignUpModal';

function SignUpForm() {
    const context = React.useContext(FirebaseContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordEye, setPasswordEye] = useState(true);
    const [confirmPasswordEye, setConfirmPasswordEye] = useState(true);
    const pwComplexityRequirement = /(?=.*[a-z])(?=.*[A-Z]).{6,16}/;

    const handlePasswordClick = () => {
        setPasswordEye(!passwordEye);
    };

    const handleConfirmPasswordClick = () => {
        setConfirmPasswordEye(!confirmPasswordEye);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    //form events;
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
    });

    const getPwStrengthIndicator = () => {
        if (!password) {
            return (
                <FontAwesomeIcon icon={faCircle} className="pw_check" style={{ color: 'white' }} />
            );
        } else if (password.length < 6 || !pwComplexityRequirement.test(password)) {
            return (
                <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className="pw_check"
                    style={{ color: 'red' }}
                />
            );
        } else {
            return (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="pw_check"
                    style={{ color: 'green' }}
                />
            );
        }
    };

    const getPwMatchesStrengthIndicator = () => {
        if (!confirmPassword) {
            return (
                <FontAwesomeIcon icon={faCircle} className="pw_check" style={{ color: 'white' }} />
            );
        } else if (confirmPassword !== password) {
            return (
                <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className="pw_check"
                    style={{ color: 'red' }}
                />
            );
        } else {
            return (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="pw_check"
                    style={{ color: 'green' }}
                />
            );
        }
    };

    //check password event - note watch is deprecated
    const passwordWatch = watch('password');

    const onSubmit = async (e, data) => {
        await context.signUp(email, password);
    };
    React.useEffect(() => {
        if (context.status === 'logged') {
            navigate('/onboarding');
        }
    }, [context.status]);

    return (
        <Layout>
            <div className="entry-container">
                <h1 className="entry-header">Join Earth Hero</h1>
                {context.message && (
                    <div className="alert alert-info" role="alert">
                        {context.message}
                    </div>
                )}
                {context.error && (
                    <div className="alert alert-warning" role="alert">
                        {context.error}
                    </div>
                )}
                <form className="entry-form" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="email" className="entry-form__label">
                        Email
                    </label>
                    <input
                        type="text"
                        className="entry-form__input"
                        id="email"
                        placeholder="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    ></input>

                    <div className="entry-form__pw-container">
                        <label className="entry-form__label" htmlFor="password">
                            Password
                        </label>
                        <div className="entry-form__pw-input">
                            <input
                                type={passwordEye === true ? 'password' : 'text'}
                                className="entry-form__input"
                                id="password"
                                {...register('password', {
                                    required: 'password required',
                                    pattern: {
                                        value: pwComplexityRequirement,
                                        message: 'password is not valid',
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'minimum length is 6',
                                    },
                                })}
                                placeholder="password"
                                name="password"
                                value={password}
                                // onChange={e => setPassword(e.target.value)}
                                onChange={e => setPassword(e.target.value)}
                            ></input>
                            {passwordEye === false ? (
                                <FontAwesomeIcon
                                    className="fa-eye-low pw_toggle"
                                    icon={faEyeLowVision}
                                    onClick={handlePasswordClick}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="fa-eye-open pw_toggle"
                                    icon={faEye}
                                    onClick={handlePasswordClick}
                                />
                            )}
                            {getPwStrengthIndicator()}
                        </div>
                        {errors.password && <span>{errors.password.message}</span>}
                    </div>

                    {/*confirm password section */}
                    <div className="entry-form__pw-container">
                        <label className="entry-form__label" htmlFor="confirmPassword">
                            Confirm Password
                        </label>
                        <div className="entry-form__pw-input">
                            <input
                                type={confirmPasswordEye === true ? 'password' : 'text'}
                                className="entry-form__input"
                                id="confirmPassword"
                                onPaste={e => {
                                    e.preventDefault();
                                    return false;
                                }}
                                {...register('confirmPassword', {
                                    required: 'confirm password required',
                                    validate: value =>
                                        value === passwordWatch || 'The passwords do not match',
                                })}
                                placeholder="Confirm password"
                                name="confirmPassword"
                                autoComplete="off"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            ></input>
                            {confirmPasswordEye === false ? (
                                <FontAwesomeIcon
                                    className="fa-eye-low-con pw_toggle"
                                    icon={faEyeLowVision}
                                    onClick={handleConfirmPasswordClick}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="fa-eye-open-con pw_toggle"
                                    icon={faEye}
                                    onClick={handleConfirmPasswordClick}
                                />
                            )}
                            {getPwMatchesStrengthIndicator()}
                        </div>
                        {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
                    </div>

                    <button
                        className="entry-form__button"
                        onClick={async () => {
                            await context.signUp(email, password);
                        }}
                    >
                        Create account
                    </button>
                </form>
                {context.loading && (
                    <div className="divider-div">
                        <div className="spinner-border text-success" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                <div className="divider-div">
                    <div className="divider"></div>
                    <span>OR</span>
                    <div className="divider"></div>
                </div>

                <p> Sign up with: </p>
                <div className="FA-icons-div">
                    <FontAwesomeIcon
                        className="facebook fa-3x icon"
                        icon={faFacebook}
                        onClick={async () => {
                            await context.signInWithFacebook();
                        }}
                        cursor="pointer"
                    />
                    <div className="google-icon-container icon">
                        <FontAwesomeIcon
                            className="google fa-3x icon"
                            icon={faGoogle}
                            onClick={async () => {
                                await context.signInWithGoogle();
                            }}
                            cursor="pointer"
                        />
                    </div>
                    {/* <FontAwesomeIcon
                        className="apple fa-3x icon"
                        icon={faApple}
                        cursor="pointer"
                    /> */}
                </div>
                <div className="signup-subtext">
                    <span> Already have an account?</span>
                    <Link to="/login" className="subtext-link">
                        Login
                    </Link>
                    <span className="subtext-separator">or</span>
                    <span>Want to explore Earth Hero first?</span>
                    <button onClick={toggleModal} className="subtext-link">
                        Skip
                    </button>
                    <SkipSignUpModal isOpen={isModalOpen} toggle={toggleModal} />
                </div>
                <p className="terms-subtext">
                    By continuing, you agree to the Privacy Policy and Terms of Service
                </p>
            </div>
        </Layout>
    );
}

export default SignUpForm;
